@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.App {
    font-family: Poppins;
    color: #252628;
}


/*Projekt mamy zrobiony na 1170px dokładnie grid wiec mozemy przyjąć że max to 1280*/

/*/ póżniej mam projekt Mobile zrobiony wiec pewnie mobile nam złapie 650 <---- w dół /*/

/*a tablet :slightly_smiling_face:  to 650 w góre i realnie nie wiem do 900px pociagnac i lecimy pozniej z web*/
